import { useEffect, useState } from 'react';
import ButtonField from 'components/fields/ButtonField';
import Progress from 'components/progress';
import { FaFileCsv } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useDropzone } from 'react-dropzone';

const UploadCSV = (props: any) => {
  const { sendDataToStepOne, handleClick, sendPrecent } = props;
  const [inputs, setInputs] = useState<any>();
  const [datafiles, setDataFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [showProgress, setShowProgress] = useState(false);
  const [fileElements, setFileElements] = useState<JSX.Element[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { 'text/csv': [] },
    maxFiles: 12,
    onDrop: (acceptedFiles) => {
      setDataFiles(acceptedFiles);
      setInputs('next');
    },
  });

  useEffect(() => {
    setProgress(sendPrecent);
    if (sendPrecent > 0) { setShowProgress(true); }
  }, [sendPrecent]);
  
  useEffect(() => {
    const mappedFiles = datafiles.map((file: File) => (
      <div key={file.name}>
        <li>
          <div className="flex flex-cols-4 flex-rows-1 mt-2 w-full">
            <div className="text-xl">
              <FaFileCsv className="text-green-500 dark:text-green-300" />
            </div>
            <div className="ml-5">{file.name}</div>
            <div className="ml-2">- {file.size} bytes</div>
            <div className="ml-2 text-xl hover:text-red-500 dark:hover:text-red-300 hover:cursor-pointer">
              <button onClick={() => {  DeleteButton(file);  }}  >
                <MdDelete />
              </button>
            </div>
          </div>
        </li>
      </div>
    ));
    setFileElements(mappedFiles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datafiles]);

  const DeleteButton = (file: File) => {
    const newFiles = datafiles.filter((f) => f !== file);
    setDataFiles(newFiles);
  };

  const handleSubmit = () => {
    sendDataToStepOne(datafiles);
  };

  useEffect(() => {
    if (progress === 100) { handleClick(inputs); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Upload your CSV file
      </h4>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="flex mt-5 mb-5 justify-center items-center bg-blue-50 px-10 py-10 bg-opacity-20 border-dashed border-2 hover:bg-opacity-50 cursor-pointer">
            <p className="cursor-pointer text-center">
              Drag 'n' drop some files here, or click to select files
              <br />
              <span className="text-gray-700 dark:text-gray-400">
                Accepted files: csv
              </span>
            </p>
          </div>
        </div>
        {fileElements.length > 0 && (
          <ul>
            <ButtonField
              id="Uploaded"
              Name="Upload File(s)"
              size="small"
              color="primary"
              onClick={handleSubmit}
            />
            {showProgress && progress > 0 && progress < 100 && (
              <div className="mt-5">
                <Progress
                  value={progress}
                  color={'blue'}
                  width={'w-full'}
                  extra="h-22"
                />
              </div>
            )}
            <h4 className="mt-5 mb-3">File(s) to be uploaded</h4>
            {fileElements}
          </ul>
        )}
      </section>
    </div>
  );
};

export default UploadCSV;