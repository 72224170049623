
import React, { useState , useEffect , useMemo } from "react";
import { RiMoneyPoundBoxFill } from "react-icons/ri";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import Card from "components/card";
import Transaction from "components/dataDisplay/Transaction";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import { barChartOptionsBalance } from 'variables/charts';

function Balance(props: any) {
  const { balance } = props;
  const [CurrentBalance, setCurrentBalance] = useState(0);
  const [TotalMIncome, setTotalMIncome] = useState(0);
  const [TotalExpense, setTotalExpense] = useState(0);
  const [TotalIncome, setTotalIncome] = useState() as any;
  const [TotalOutcome, setTotalOutcome] = useState() as any;
  const [HighestSepending, setHighestSepending] = useState([]);
  const [ShowValues, setShowValues] = useState(5);


  useEffect(() => {
    let CurrentBalanceLoop:any = [];
    let TotalMIncomeLoop = [];
    let TotalExpenseLoop = [];
    let HighestSependingLoop = [];
    let TotalIncomeLoop = [];
    let TotalOutcomeLoop = [];

    for (const items in balance) {
      if (balance[items]["Balance"]) {
        CurrentBalanceLoop.push(balance[items]["Balance"]);
      }

      if (balance[items]["Total-Credits"]) {
        TotalMIncomeLoop.push(balance[items]["Total-Credits"]);
      }

      if (balance[items]["Total-Debits"]) {
        TotalExpenseLoop.push(balance[items]["Total-Debits"]);
      }
      
      if (balance[items]["Largest-Credit-Transaction"]) {
        TotalIncomeLoop.push(balance[items]["Largest-Credit-Transaction"]);
      }
      
      if (balance[items]["Largest-Debit-Transaction"]) {
        TotalOutcomeLoop.push(balance[items]["Largest-Debit-Transaction"]);
      }
      
      if (balance[items]["Top-5-highest-spending-vendors"]) {
        HighestSependingLoop.push(balance[items]["Top-5-highest-spending-vendors"]);
      }

    }


  CurrentBalanceLoop = CurrentBalanceLoop.flat().map((value: string) => parseFloat(value.replace('£', ''))).reduce((a:any, b:any) => a + b, 0);

  TotalMIncomeLoop = TotalMIncomeLoop.flat().map((value: string) => parseFloat(value.replace('£', ''))).reduce((a:any, b:any) => a + b, 0);

  TotalExpenseLoop = TotalExpenseLoop.flat().map((value: string) => parseFloat(value.replace('£', ''))).reduce((a:any, b:any) => a + b, 0);

  HighestSependingLoop = HighestSependingLoop.flat().reduce((prev:any, curr:any) => {
    return {...prev, ...curr};
  }, {});

if(TotalIncomeLoop != null || TotalOutcomeLoop != null ){
  TotalOutcomeLoop = TotalOutcomeLoop.reduce((prev, current) => {
    const prevValue = parseFloat(prev.split(' for ')[0].replace(',', ''));
    const currentValue = parseFloat(current.split(' for ')[0].replace(',', ''));
    return (prevValue > currentValue) ? prev : current;
  }, "");

  TotalIncomeLoop = TotalIncomeLoop.reduce((prev, current) => {
    const prevValue = parseFloat(prev.split(' for ')[0].replace(',', ''));
    const currentValue = parseFloat(current.split(' for ')[0].replace(',', ''));
    return (prevValue > currentValue) ? prev : current;
  }, "");
  
  setTotalIncome(TotalIncomeLoop);
  setTotalOutcome(TotalOutcomeLoop);

} else {
  setTotalIncome("No data available");
  setTotalOutcome("No data available");
}

    setCurrentBalance(CurrentBalanceLoop);
    setTotalMIncome(TotalMIncomeLoop);
    setTotalExpense(TotalExpenseLoop);

    setHighestSepending(HighestSependingLoop);

  }, [balance]);


const dataLength = Object.keys(HighestSepending).length;

console.log(dataLength);

  return (
    <Card extra={"p-4 "}>
      <div
        className={`bg-image relative mt-[7px] flex h-fit w-full justify-between rounded-md bg-brand-900 bg-ballanceDashboard bg-cover bg-right px-3 py-4 text-white dark:bg-navy-700`}
      >
        <div>
          <p className="text-sm font-medium">
            Current Total Balance
          </p>
          <h5 className="text-[34px] !font-bold">
          { 
            CurrentBalance 
              ? CurrentBalance.toLocaleString('en-US', { style: 'currency', currency: 'GBP' }) 
              : "No data available"
          }

          </h5>
        </div>
      </div>
      <p className="mt-[19px] text-sm font-medium text-gray-600">Breakdown</p>



      {/* Bill section */}

      
    { (!TotalMIncome || !TotalExpense) ? (
    <div className="flex items-center justify-center text-center w-full h-full">
      <p className="text-lg font-bold text-gray-400">
        No data available please check back later
      </p>
    </div>
  ) : (
    <div className="">
      <Transaction
        title="Money in"
        date=""
        sum={TotalMIncome.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}
        mb="mb-[20px]"
        icon={
          <RiMoneyPoundBoxFill className="text-green-400 dark:text-green-300" />
        }
      />
      <Transaction
        title="Money out"
        date=""
        sum={` - ${TotalExpense.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}`}
        mb="mb-[20px]"
        icon={<RiMoneyPoundBoxFill className="text-red-400 dark:text-red-300" />}
      />

<Transaction
        title="Highest Incoming"
        date=""
        sum={` £ ${TotalIncome}`}
        extra="text-end"
        mb="mb-[20px]"
        icon={
          <RiMoneyPoundBoxFill className="text-green-400 dark:text-green-300" />
      }
      />

<Transaction
        title="Highest Outgoing"
        date=""
        sum={` - £ ${TotalOutcome}`}
        extra="text-end"
        mb="mb-[20px]"
        icon={
          <RiMoneyPoundBoxFill className="text-red-400 dark:text-red-300" />
      }
      />

      <div className="mt-[20px]">
        <p className="text-sm font-medium text-gray-600">Top 5 highest spending vendors</p>
        <div className="flex flex-col gap-2 mt-[10px]">

          {Object.entries(HighestSepending).slice(0, ShowValues).map(([vendor, amount], index) => (

            <div key={index} className="p-4 flex items-center justify-between w-full">
              <p className="text-sm font-medium">{vendor}</p>
              <p className="text-sm ">{`${amount.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}`}</p>
            </div>
          ))}
            

          {dataLength > ShowValues ? (
            <div className="flex justify-center mt-3">
              <button className="p-2 text-sm font-medium text-brand-500 dark:text-white hover:cursor-pointer"
              onClick={()=> setShowValues(dataLength)}
              >
                <div className="mb-auto" />
                <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer">
                  <div className="text-sm font-bold text-brand-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
                    Show all transactions
                  </div>
                  <div className="text-xl font-bold text-brand-500 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
                    <BsArrowDown />
                  </div>
                </div>
              </button>
            </div>
          ) : dataLength === ShowValues ? (
            <div className="flex justify-center mt-3">
              <button className="p-2 text-sm font-medium text-brand-500 dark:text-white hover:cursor-pointer"
              onClick={()=> setShowValues(10)}
              >
                <div className="mb-auto" />
                <div className="flex w-full items-center justify-end gap-1 hover:cursor-pointer">
                  <div className="text-sm font-bold text-brand-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
                    Hide transactions
                  </div>
                  <div className="text-xl font-bold text-brand-500 transition-all hover:translate-x-1 hover:cursor-pointer dark:text-white">
                    <BsArrowUp />
                  </div>
                </div>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
  }
    </Card>
  );
}

export default Balance;
