import Card from 'components/card';
import { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
function OverallRevenue(props:any) {
  const  data = props.data;
  const [MoneyOutData, setMoneyOutData] = useState({});  
  const [MoneyInData, setMoneyInData] = useState({});
  const [KeyData, setKeyData] = useState([]);

  useEffect(() => {
    let MoneyOut = [];
    let MoneyIn = [];
    let Keys = [];

    for (const items in data) {
      if(data[items]["Money-Out-per-month"]){
        MoneyOut.push(data[items]["Money-Out-per-month"]);
      }if(data[items]["MoneyIn-per-month"]){
        MoneyIn.push(data[items]["MoneyIn-per-month"]);
      }
      Keys.push(Object.keys(data[items]["MoneyIn-per-month"]));
    }

    MoneyOut = MoneyOut.flat().reduce((prev, curr) => {
      return {...prev, ...curr};
    }, {});

    MoneyIn = MoneyIn.flat().reduce((prev, curr) => {
      return {...prev, ...curr};
    }, {});

    Keys = Keys.flat();

    setMoneyOutData(MoneyOut);
    setMoneyInData(MoneyIn);
    setKeyData(Keys);
  }, [data]);


  const MoneyOutValues = Object.values(MoneyOutData);
  const MoneyInValues = Object.values(MoneyInData);
  
  const OutData = MoneyOutValues;
  const InData = MoneyInValues;
  const Key = KeyData;

  const lineChartOptions:any = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "var(--color-500)",
      },
    },
    colors: ["var(--color-500)", "#39B8FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle" as const,
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true as const,
    },
    tooltip: {
      theme: "dark",
      y:{
        show: false,
        formatter: function (value:any) {
          return `${value.toLocaleString('en-US', { style: 'currency', currency: 'GBP' })}`;
      }
    }
  },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "string",
      categories: Key,
      labels: {
        style: {
          colors: "#828899",
          fontSize: "10px",
          fontWeight: "700",
        },
      },
      decimalInFloat: 2,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
      
    },
    color: ["#7551FF", "#39B8FF"],
  }

const series:any = [
  {
    name: "Money In",
    data: InData,
  },
  {
    name: "Money Out",
    data: OutData,
  },
]

  return (
    <Card extra={'h-full pb-8 px-6 pt-6'}>
      {/* Charts */}
      <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Monthly Revenue Overview
        </p>
        { (!data || !InData || !OutData) ? (
    <div className="flex items-center justify-center w-full h-full">
      <p className="text-lg font-bold text-gray-400">
        No data available please check back later
      </p>
    </div>
  ) : (
        <ReactApexChart
            options={lineChartOptions}
            series={series}
            type="bar"
            width="100%"
            height="100%"
          />
  )}
        </div>
      </div>
    </Card>
  );
}

export default OverallRevenue;
