import React from 'react';
import { BreadcrumbsItem, Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../../routes';

const findRouteName = (pathname: string) => {
  for (const route of routes) {
    if (route.path === pathname) {
      return route.name;
    }
    if (route.items) {
      for (const subRoute of route.items) {
        if (subRoute.path === pathname) {
          return subRoute.name;
        }
      }
    }
  }
  return '';
};

//TODO: Fix the breadcrumbs

const BreadcrumbsComponent: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className="">
      {/* <Breadcrumbs

        item={Link}
 
      >
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return (
            <BreadcrumbsItem key={to} to={to}>
              {findRouteName(to)}
            </BreadcrumbsItem>
          );
        })}
      </Breadcrumbs> */}
    </div>
  );
};

export default BreadcrumbsComponent;
