import { Button } from '@chakra-ui/react';

function ButtonField(props: {
  Name?: any;
  size?: string;
  color?: string;
  onClick?: any;
  disabled?: boolean;
  type?: any;
  className?: string;
  extra?: string;
  id?: string;
  isLoading?: boolean;
}) {
  const {
    Name,
    size,
    color,
    onClick,
    disabled,
    type,
    extra = '', 
    id,
    isLoading,
  } = props;

  /** 
   * The ButtonField component is a reusable button component that can be used in different parts of the application.
   * 
   * It accepts the following props:
   *  - Name: The text to be displayed on the button
   *  - size: The size of the button. It can be 'small', 'regular', or 'large'
   * - color: The color of the button. It can be 'primary', 'secondary', 'lightPrimary', 'lightSecondary', 'clear', or 'dark'
   * - onClick: The function to be called when the button is clicked
   * - disabled: A boolean value that determines whether the button is disabled or not
   * - type: The type of the button. It can be 'button', 'submit', or 'reset'
   * - extra: Additional classes to be added to the button
   * - id: The id of the button
   * - isLoading: A boolean value that determines whether the button is in a loading state or not
   * 
   */

  return (
    <Button
      id={id}
      type={type}
      size={size}
      colorScheme={color}
      isLoading={isLoading}
      onClick={onClick}
      disabled={disabled}
      className={`w-full p-2 hover:shadow-lg rounded-md bg-brand-500 py- text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${extra}`}
    >
      {Name}
    </Button>
  );
}

export default ButtonField;