/**
 * @file It contains the Banking Graph
 * 
 * This component is used to display the Banking Graph that will show the revenue and profit for each month based on the CSV file the provide
 * The way That I am working out the profit is by taking the revenue and subtracting the expenses from it
 * The Revenue is the total amount of money that is coming in from the bank records this will also include petty cash that they may have added via the invoice section. 
 */

import Card from 'components/card';
import ReactApexChart from 'react-apexcharts';
import { IoHelp } from "react-icons/io5";
import ButtonField from 'components/fields/ButtonField';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button
} from '@chakra-ui/react'

import { toast } from 'react-toastify';
import {
  lineChartDataOverallRevenue,
  lineChartOptionsOverallRevenue,
} from 'variables/charts';


/** 
 * 
 * TODO: 
 * 1. Add two buttons that the user then can click to change the graph to show the revenue and profit by year or by month.
 * TODO:
 * Calculate the profit by taking the revenue and subtracting the expenses from it
 * TODO: 
 * Add a modal that when clicked will allow the user to see how each section of the graph is calculated

 */

const Banner1 = () => {

  const ChangeGraphToYear = () => {
    toast.success("Year");
  };

  const ChangeGraphToMonth = () => {
    toast.success("Month");
  };

  return (
    <div>
    <Card extra={'w-full sm:overflow-auto'}>
      <div className="relative mr-2 ml-2 mt-2">
          <div className="grid grid-cols-2 grid-rows-1 mt-2">
          <h1 className="font-bold text-2xl ml-5 mb-5 justify-start text-start">
          Revenue, Profits and Projected Profits 
          </h1>
            <div className="relative w-full">
              <div className="flex justify-end">
                <div className="flex justify-end items-end p-1">



                <ButtonField
                color="clear" 
                type="submit" 
                Name="Month"
                onClick={() => ChangeGraphToMonth()}
                />
                </div>
                <div className="flex justify-end items-end p-1">
                <ButtonField
                color="clear" 
                type="submit" 
                Name="Year"
                onClick={() => ChangeGraphToYear()}
                />
<div className='mt-'>
                <Popover>
                  <PopoverTrigger>
                    <Button
                      className='w-full p-2 hover:shadow-lg rounded-md bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ml-2'
                    >
                      <IoHelp />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className='relative shadow-xl bg-blue-gray-50 text-black px-5 py-5 rounded-xl'>
                    <PopoverArrow className='bg-blue-400 mt-5' />
                    <div className='flex items-center justify-between'>
                      <PopoverHeader className='text-xl font-bold text-center'>
                        Help
                      </PopoverHeader>
                      {/* <PopoverCloseButton className='flex items-end justify-end p-5' /> */}
                    </div>
                    <PopoverBody>
                      Month - This will show the revenue and profit for each month
                      <br />
                      Year - This will show the revenue and profit for each year
                      </PopoverBody>
                  </PopoverContent>
                </Popover>

</div>
                </div>
              </div>
            </div>
        </div>    
            
        <div>
          <ReactApexChart
            options={lineChartOptionsOverallRevenue}
            series={lineChartDataOverallRevenue}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </Card>

    </div>
  );
};

export default Banner1;

/**
 *  Profit = Revenue - Expenses
 */