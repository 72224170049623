import Card from 'components/card';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaMoneyCheckDollar } from 'react-icons/fa6';
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { AiFillFileAdd } from "react-icons/ai";
//assets

function Actions() {

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/'; //Needed for the redirect
  return (
    <Card extra={'w-full h-auto 3xl:h-auto px-3 pt-3 2xl:pb-5'}>
      {/* Header */}
      <div className="flex items-center justify-between px-3 pt-3">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Quick Actions
        </h4>
        
      </div>
      {/* Card bellow info */}
      <div className="mt-4 w-full rounded-md bg-lightPrimary px-3 py-3 dark:bg-navy-700">
        <p className="text-sm font-medium text-gray-600">
          Here are some quick actions you can do that will help you manage your
          work quicker.
        </p>
      </div>

      {/* Buttons */}
      <div className="mt-10 flex w-full justify-between px-3 md:justify-around xl:justify-between">
        <div className="flex flex-col items-center justify-center text-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-brand-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-brand-300 dark:hover:!bg-white/20 dark:active:!bg-white/10"
          onClick={()=>{navigate('/main/banking/add', {state: {from: from}})}}
          >
            <AiFillFileAdd />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Add bank <br/> Statement
          </h5>
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-yellow-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-yellow-300 dark:hover:!bg-white/20 dark:active:!bg-white/10"
          onClick={()=>{navigate('/main/invoices/add', {state: {from: from}})}}
          >
            <AiFillFileAdd />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Add <br /> Invoices
          </h5>
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <button className="linear flex items-center justify-center rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-green-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-green-300 dark:hover:!bg-white/20 dark:active:!bg-white/10"
          onClick={()=>{navigate('/main/banking/bank-csv', {state: {from: from}})}}
          >
            <FaMoneyCheckDollar />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Check <br/> Statements
          </h5>
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <button className="linear flex items-center justify-cent text-centerer rounded-full border-[1px] border-gray-200 bg-white p-3 text-2xl text-blue-500 shadow-2xl transition duration-200 hover:cursor-pointer hover:!bg-gray-50 active:!bg-gray-100 dark:border-white/20 dark:!bg-navy-700 dark:text-blue-300 dark:hover:!bg-white/20 dark:active:!bg-white/10"
          onClick={()=>{navigate('/main/invoice/view-invoices', {state: {from: from}})}}>
            <LiaFileInvoiceSolid />
          </button>
          <h5 className="mt-1 text-sm font-medium text-navy-700 dark:text-white">
            Check <br/> Invoices
          </h5>
        </div>
      </div>
    </Card>
  );
}

export default Actions;
