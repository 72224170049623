
//Importing Non Styling Components
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

//Importing Styling Components

//Importing Components
import ProfitMargins from "./components/ProfitMargins";
import Action from "./components/Actions";

import SupplierUnallocated from "./components/SupplierUnallocated";
import EmployeeUnallocated from "./components/EmployeeUnallocated";
import CurrentBankingStatus from "./components/CurrentBankingStatus";

//Testing Data
import tableDataTopCreators from "./variables/tableDataTopCreators";

const Marketplace = () => {
  return (
    <div className="mt-3 grid grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <Helmet>
        <title>Banking Dashboard - Helios</title> 
        <meta name="description" content="Banking Dashboard where you can see all of your companies " />
      </Helmet>
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
  
        {/* bar Chart showing margins */}
        <ProfitMargins />

        {/* NFTs trending card */}
        <div className="z-20 grid gird-cols-2 gap-5 md:grid-cols-2 mt-5">
        <SupplierUnallocated />
        <EmployeeUnallocated />
        </div>
      </div>

      {/* right side section */}

      <div className="col-span-1 h-full w-full rounded-md 2xl:col-span-1">
        <Action/>
        <div className="mb-5" />
        <CurrentBankingStatus tableData={tableDataTopCreators} />
      </div>
    </div>
  );
};

export default Marketplace;
