import useAuth from '../hooks/useAuth';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

const RouteNavigator: FC = () => {
  const { auth } = useAuth();

  if (auth.roles) {
    return <Navigate to="/main/" replace />;
  } else {
    return <Navigate to={"/auth/sign-in/"} replace />;
  }
};
export default RouteNavigator;