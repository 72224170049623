import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
// import { MdDarkMode, MdOutlineDarkMode } from "react-icons/md";
import { RiMoonFill, RiSunFill } from "react-icons/ri";


import { useNavigate, Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
// import Configurator from "./Configurator";
import Avatar from 'react-avatar';
import Notification from "./components/notifications";
import useLogout from "hooks/useLogout";
import secureLocalStorage from 'react-secure-storage';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  [x: string]: any;
}) => {
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;

  const navigate = useNavigate(); //Used to navigate to other pages
  const logout = useLogout();

  const [darkmode, setDarkmode] = React.useState(
    localStorage.getItem("darkmode") === "true" ? true : false
  ) as any;

  const setDarkmodeLocal = (value: boolean) => {

    localStorage.setItem("darkmode", String(value));

    if (localStorage.getItem("darkmode") === "true") {  document.body.classList.add("dark");} 
    else {  document.body.classList.remove("dark"); }

    setDarkmode(value);
  }
  const Name = secureLocalStorage.getItem('Name');

    //Sign Out Function
  const signOut = async () => {
      await logout();
      secureLocalStorage.clear();
      localStorage.setItem("darkmode", (darkmode));
      navigate('/auth/sign-in/');
    };

  return (
    <nav
      className={`duration-175 linear fixed right-3 top-3 flex flex-row flex-wrap items-center justify-between rounded-md bg-white/30 transition-all ${
        mini === false
          ? "w-[calc(100vw_-_1%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
          : mini === true && hovered === true
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
          : "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]"
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:right-[30px] md:top-4 xl:top-[20px]`}
    >
      <div className="ml-[6px]">
        <div className="h-6 w-[224px]">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Home
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "} 
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full px-2 py-2 md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full border-[1px] border-gray-200 bg-gray-50 bg-clip-border dark:border-[#ffffff33] dark:bg-navy-800 dark:text-white dark:shadow-none xl:w-[250px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-gray-50 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-800 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
          <Notification />
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <Avatar 
            src={secureLocalStorage.getItem('ProfilePic') ? secureLocalStorage.getItem('ProfilePic').toString() : ""}
            name={secureLocalStorage.getItem('Name')? secureLocalStorage.getItem('Name').toString() : "No Account Name"} 
            size="40" 
            round={true}
            textSizeRatio={2.75}
            className="flex cursor-pointer font-semibold text-gray-600 dark:text-white items-center justify-center text-center"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-md bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                <div className="grid grid-cols-2 grid-rows-1 gap-2">
                    <div >
                      <p className="text-sm font-bold text-navy-700 dark:text-white text-wrap">
                        👋 Hey, {String(Name)}
                      </p>{" "}
                    </div>
                    <div className="flex items-end justify-end">
                    <p className="text-lg font-semibold dark:text-white cursor-pointer">
                      {darkmode ? (
                        <RiSunFill onClick={() => setDarkmodeLocal(false)} />
                      ) : (
                        <RiMoonFill onClick={() => setDarkmodeLocal(true)} className="text-brand-400" />
                      )}
                    </p>
                    {/* <Configurator
                      mini={props.mini}
                      setMini={props.setMini}
                      theme={props.theme}
                      setTheme={props.setTheme}
                      darkmode={darkmode}
                      setDarkmode={setDarkmode}
                    /> */}
                    <div
                      className="cursor-pointer text-gray-600"
                      onClick={() => {
                        if (localStorage.getItem("darkmode") === "true" ){
                          document.body.classList.remove("dark");
                          localStorage.setItem("darkmode", "false");
                        } else {
                          document.body.classList.add("dark");
                          localStorage.setItem("darkmode", "true");
                        }
                      }}
                    ></div>
                    </div>
                </div>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="ml-4 mt-3 flex flex-col">
              <Link
                  to="/main/profile/my-profile/"
                  className="text-sm mb-2 text-gray-800 dark:text-white hover:dark:text-white"
                >
                  My Profile
                </Link>
                <Link
                  to="/main/profile/settings/"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                <Link
                  to="#"
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={signOut}
                >
                  Log Out
                </Link>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
