import Nft2 from "assets/img/nfts/Nft2.png";
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";

import { FaEthereum } from "react-icons/fa";
import Card from "components/card";

const SupplierUnallocated = () => {
  const HistoryData = [
    {
      image: Nft1,
      title: "Bank of America Corporation",
      owner: "01/01/2021",
      price: 1357.58,
    },
    {
      image: Nft2,
      title: "NextEra Energy, Inc.",
      owner: "01/01/2021",
      price: 1752.44,
    },
    {
      image: Nft3,
      title: "HSBC Holdings plc",
      owner: "01/01/2021",
      price: 1736.77,
    },
    {
      image: Nft4,
      title: "Syngenta AG",
      owner: "01/01/2021",
      price: 1296.47,
    },
    {
      image: Nft5,
      title: "MasTec, Inc.",
      owner: "01/01/2021",
      price: 1191.93,
    },
    {
      image: Nft6,
      title: "Welltower Inc.",
      owner: "01/01/2021",
      price: 465.82,
    },
  ];

  //TODO: The button will open a modal with the full list of unallocated supplier payments here they will be able to assign the payments to the correct supplier.

  return (
    <Card extra={"mt-3 !z-5 overflow-hidden px-4 pb-4"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          Recent Unallocated Supplier Payments
        </div>
        <button className="linear rounded-md bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
          See all
        </button>
      </div>

      {/* History CardData */}

      {HistoryData.map((data, index) => (
        <div
          key={index}
          className="flex h-full w-full items-start justify-between rounded-md border-[1px] border-[transparent] bg-white px-3 py-[20px] transition-all duration-75 hover:border-gray-200 dark:!bg-navy-800 dark:hover:border-white/20"
        >
          <div className="flex items-center gap-3">
            <div className="flex h-16 w-16 items-center justify-center">
              <img
                className="h-full w-full rounded-md"
                src={data.image}
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-bold text-navy-700 dark:text-white">
                {" "}
                {data.title}
              </h5>
              <p className="mt-1 text-sm font-normal text-gray-600">
                {" "}
                {data.owner}{" "}
              </p>
            </div>
          </div>

          <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
            <div className="ml-1 flex items-center text-sm font-bold text-navy-700 dark:text-white">
              <p> {} </p>
              {data.price.toLocaleString("en-US", {
                style: "currency",
                currency: "GBP",
              })}
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};

export default SupplierUnallocated;
