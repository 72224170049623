
import {useState, useEffect} from "react";
import SearchTableUsers from "./components/SearchTableUsersOverview";
import Card from "components/card";
import { useParams } from "react-router-dom";
import {toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

const UserOverview = () => {
  const { id } = useParams()
  const { auth } = useAuth();
  const AxiosPrivate = useAxiosPrivate();
  const [TableData, setTableData] = useState<any>([]);
  const [isLoading , setIsLoading] = useState<boolean>(false);

  /**
   *  Call back to the Backend with the ID of the file
   *  to get the data of the file
   *  backend will send back a JSOn representation of the CSV file
   * 
   *  Send that to the table component to display the data
   * 
   *
   */

  const GetData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetBankingCSVData + id
        );
        setTableData(response?.data);
        setIsLoading(false);
    } catch (error: any) {
      if(error.response.status === 403){
        GetData();
      }else{
        toast.error("Error:" + (error as any).response?.data?.message);
      }
  }
  };

  const UpdateData = (data: any) => {
    setIsLoading(data);
  };

  useEffect(() => {
    GetData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);


  return (
    <Card extra={"w-full h-full mt-11 sm:mt-10 md:mt-8"}>
      <SearchTableUsers 
      tableData={TableData}
      updateData={UpdateData} 
      />
    </Card>
  );
};

export default UserOverview;
