type RowObj = {
	name: string[];
	Items: number; 
	Value: number; 
};

const tableColumnsTopCreators: RowObj[] = [
	{
    name: ["Unallocated Bank Transactions"],
    Items : 9821,
    Value:15309.03
  },
  {
    name: ["Unallocated Supplier Payments"],
    Items : 9821,
    Value:25364.15
  },
  {
    name: ["Unallocated Wages Payments"],
    Items : 9821,
    Value:20512.79
  },
  // {
  //   name: ["@karl.will02","https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"],
  //   artworks : 7032,
  //   rating:87
  // },
  // {
  //   name: ["@andreea.1z","https://images.unsplash.com/photo-1573766064535-6d5d4e62bf9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1315&q=80"],
  //   artworks : 5204,
  //   rating:82
  // },
  // {
  //   name: ["@abraham47.y","https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1780&q=80"],
  //   artworks : 4309,
  //   rating:68
  // },
  // {
  //   name: ["@simmmple.web","https://i.ibb.co/7p0d1Cd/Frame-24.png"],
  //   artworks : 3871,
  //   rating:55
  // },
  // {
  //   name: ["@horizon.ui","https://i.ibb.co/NSJYQYD/Horizon-UI-Avatar.png"],
  //   artworks : 3152,
  //   rating:46
  // },
  // {
  //   name: ["@venus.sys","https://scontent.fotp8-1.fna.fbcdn.net/v/t1.6435-9/202074221_101421322193072_8201469573182417214_n.png?_nc_cat=108&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=78fLje1ri2sAX-9M9k1&_nc_ht=scontent.fotp8-1.fna&oh=00_AT-yCcI9whoAZcSaGmJQK53xRa1CldCaj2q5qJlPvDvHRA&oe=626E41FF"],
  //   artworks : 2907,
  //   rating:38
  // }
];

export default tableColumnsTopCreators;
