import {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import SearchableTable from "./components/SearchTableUsersOverview";

import {toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";


const UserReport = () => {
  const { auth } = useAuth();
  const AxiosPrivate = useAxiosPrivate();
  const [Data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const GetData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetBankingCSV + auth.UserID
        );
        setData(response.data);
    } catch (error: any) {
      if(error.response.status === 403){
        GetData();
      }else{
        toast.error("Error:" + (error as any).response?.data?.message);
      }
  }
  };

  useEffect(() => {
    GetData();
    setIsLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
    
  return (
    <div className="mt-3 h-full w-full">
      <Helmet>
        <title>Banking Overview | Access your banking statements.</title>
        <meta
          name="description"
          content="Banking Overview | Access your banking statements."
        />
      </Helmet>

      {/* Stats and talbes*/}
      <div className="grid w-full grid-cols-11 gap-5 rounded-md">
        <div className="col-span-11 h-full w-full rounded-md md:col-span-11 lg:col-span-11 xl:col-span-11 2xl:col-span-11">
          {/* <UserReportsTable tableData={TableData} /> */}
          <SearchableTable tableData={Data} />
        </div>
      </div>
    </div>
  );
};

export default UserReport;
