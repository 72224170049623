
const Routes = {
    /**     Auth    */
    login: '/auth/login/',
    register: '/auth/register/',
    logout: '/auth/logout/',
    verification: '/auth/verification/',
    forgotPassword: '/auth/password/forgot-password/',
    resetPassword: '/auth/password/reset-password/',
    refreshToken: '/auth/refresh-token',
    EmailActivationReset: '/confirm/web/',
    
    /** Account Settings */
    GetUserAddress: 'api/account-settings/get-address/',
    GetUserDetails: 'api/account-settings/get-details/',
    UpdateUserAddress: 'api/account-settings/update-address/',
    UpdateUserDetails: 'api/account-settings/update-details/',
    UpdateUserPassword: 'api/account-settings/update-password/',

    /**     User    */
    GetAUser: 'api/users/get-details/',
    GetAListOfUsers: 'api/users/get-all/',
    AddAUser: 'api/users/add/',
    EditAUser: 'api/users/edit/',
    DeleteAUser: 'api/users/delete/',

    /** DashBoard    */
    GetAdminDashBoard: '/api/system/dashboard/admin/',
    GetManagerDashBoard: '/api/system/dashboard/manager/',
    GetEmployeeDashBoard: '/api/system/dashboard/employee/',
    GetAdminTableDashBoardData: '/api/file-processing/convert/',

    /** System Settings */
    GetCompanyName: '/api/sysSettings/get-company/',
    AddCompany: '/api/sysSettings/add-company/',
    EditCompany: '/api/sysSettings/edit-company/',
    DeleteCompany: '/api/sysSettings/delete-company/',
    GetCompanyList: '/api/sysSettings/get-company-list/',
    GetCompanyDetails: '/api/sysSettings/get-company-details/',
    GetCompanyAddress: '/api/sysSettings/get-company-address/',
    GetCompanyContact: '/api/sysSettings/get-company-contact/',
    GetCompanyLogo: '/api/sysSettings/get-company-logo/',

    /**     Notifications    */
    GetNotifications: '/api/notifications/show/',// /api/notifications/show/:id/:skipValue
    CreateNotification: '/api/notifications/create/',
    DeleteSingleNotification: '/api/notifications/delete/',
    DeleteAllNotifications: '/api/notifications/delete-all/',

    /** Image and File */
    UploadImage: '/api/images/upload/image/',
    DeleteImage: '/api/images/delete/image/',

    UploadFile: '/api/file-processing/upload/file/',
    DeleteFile: '/api/file-processing/delete/file/',
    GetAllFiles: '/api/file-processing/get-all-files/',
    GetFileByType: '/api/file-processing/get-files-by-type/', 
    /*  /api/file-processing/get-files-by-type/:id/:type  */

    /**     Banking    */
    // GetCashPayments: '/api/banking/get-cash-payments/',
    GetCashPaymentsByYear: '/api/banking/get-cash-payments-by-date/',
    CreateCashPayment: '/api/banking/create-cash-payment/',
    UpdateCashPayment: '/api/banking/update-cash-payment/',
    DeleteCashPayment: '/api/banking/delete-cash-payment/',
    AutoMatchPayments: '/api/banking/auto-match-payments/',
    GetBankingCSV: '/api/file-processing/get-all-files/',
    DownloadSelectedFile: 'api/file-processing/download/',
    GetBankingCSVData: '/api/file-processing/parse/csv/',

    /**     Jobs    */
    GetAllJobList: '/api/job/get-job/',
    GetSelectedJob: '/api/job/job-selected/',
    GetJobsSupplierList: '/api/job/get-job-selected-supplier/',
    GetJobFilterByCustomer: '/api/job/filter/',
    CreateJob: '/api/job/new/',
    UpdateJob: '/api/job/edit/',


    /**     Customer    */
    GetCustomerList: '/api/customer/customer-list/',
    GetSelectedCustomer: '/api/customer/get-customer/',
    CreateCustomer: '/api/customer/create/',
    UpdateCustomer: '/api/customer/edit/',
    DeleteCustomer: '/api/customer/delete-customer/',

    /**     Supplier    */
    GetSupplierList: '/api/supplier/',
    GetCustomerAsSupplier: '/api/supplier/get-Customers/',
    CreateSupplier: '/api/supplier/new/',
    GetSelectedSupplier: '/api/supplier/get/',
    EditSelectedSupplier: '/api/supplier/edit/',
    GetJobData: '/api/supplier/getJobCount/',

}

export default Routes;