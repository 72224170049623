import { useEffect } from 'react';
import HashLoader from "react-spinners/HashLoader";
import { toast } from 'react-toastify';

const Processing = (props: any) => {
  const { dataComplete, handleClick } = props;

  useEffect(() => {
    setTimeout(() => {
      toast.success("Processing complete");
      handleClick("next");
    }, 8000);
  }, [dataComplete, handleClick]);

  return (
    <div className="h-full w-full rounded-md px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Processing
      </h4>
      <p>
        Your statement(s) is being processed. This may take a few minutes.
      </p>

      <div className="flex mt-10 mb-10 justify-center items-center">
        <HashLoader 
          color={"#2563EB"} 
          loading={dataComplete} 
          size={80}
          speedMultiplier={0.9}
        />
      </div>
    </div>
  );
};

export default Processing;