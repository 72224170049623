
import {useState, useEffect} from "react";
import SearchTableUsers from "./components/SearchTableUsersOverview";

import Card from "components/card";

import {toast } from "react-toastify";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAuth from "hooks/useAuth";
import BackendRoutes from "api/BackendRoutes/BackendRoutes";

const UserOverview = () => {

  const { auth } = useAuth();
  const AxiosPrivate = useAxiosPrivate();
  const [TableData, setTableData] = useState<any>([]);
  const [isLoading , setIsLoading] = useState<boolean>(false);

  const GetData = async () => {
    try {
      const response = await AxiosPrivate.get(
        BackendRoutes.GetCustomerList + auth.UserID
        );
        setTableData(response?.data);
        setIsLoading(false);
    } catch (error: any) {
      if(error.response.status === 403){
        GetData();
      }else{
        console.log(error as any); //TODO : Handle Error
        toast.error("Error:" + (error as any).response?.data?.message);
      }
  }
  };

  const UpdateData = (data: any) => {
    setIsLoading(data);
  };

  useEffect(() => {
    GetData();
  }, [isLoading]);


  return (
    <Card extra={"w-full h-full mt-11 sm:mt-10 md:mt-8"}>
      <SearchTableUsers 
      tableData={TableData}
      updateData={UpdateData} 
      />
    </Card>
  );
};

export default UserOverview;
